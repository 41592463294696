@media (min-width: 900px) and (max-width: 1100px) {
    .responsive-app-bar {
      width: 50px !important;
    }
  }

@media (min-width: 768px) and (max-width: 899px) {
  .responsive-app-bar {
    width: 50px !important;
  }
}

.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root,
.MuiButtonBase-root-MuiListItemButton-root, .css-1uwabd6{
  height: 50px;
  border-radius: 10px;
}
.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root:hover,.MuiButtonBase-root-MuiListItemButton-root:hover, .css-1uwabd6:hover{
  height: 50px;
  border-radius: 10px;
}

.css-hyum1k-MuiToolbar-root{
  /* width: -webkit-fill-available; */
  height: 0;
}