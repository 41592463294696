@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

@layer base {
  html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: "Poppins", sans-serif;
  }

  /* .css-z4el9r-MuiButtonBase-root-MuiTab-root.Mui-selected,.css-m6jb6t.Mui-selected {
    border-radius: 10px !important;
    color: #ffffff !important;
    background-image: linear-gradient(90deg, #4d22ae, #974ec0) !important;
  } */

  *{
    font-family: 'Poppins';
  }

  .MuiInputBase-input-MuiOutlinedInput-input,
  .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select,
  .MuiInputBase-root-MuiOutlinedInput-root,
  .MuiInputBase-input-MuiOutlinedInput-input,
  .location-search-input, .css-1x5jdmq, .css-qiwgdb,.css-1h9r77c, .css-1uvydh2, .css-1h9r77c
    {
    height: 47px !important;
    padding: 0px 15px !important;
    font-size: 13px !important;
    font-family: "Poppins" !important;
    display: flex !important;
    align-items: center !important;
  }
  .css-cwhad8-MuiDateCalendar-root {
    width: 100% !important;
  }
  .css-12mkn7b-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton, .css-1wjkg3 {
    display: none !important;
  }
  .css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel,
  .MuiTypography-root,
  .MuiPickersCalendarHeader-labelContainer,
  .MuiTypography-root-MuiDayCalendar-weekDayLabel,
  .MuiButtonBase-root-MuiPickersDay-root,
  .MuiTablePagination-selectLabel,
  .MuiTablePagination-displayedRows,
  .MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input,
  .MuiInputBase-input-MuiOutlinedInput-input,
  .MuiFormHelperText-root {
    font-family: "Poppins", sans-serif !important;
  }
  .MuiFormHelperText-root {
    margin: 3px 14px 0 !important;
  }
  .MuiListItemIcon-root {
    min-width: 46px !important;
  }
  .MuiInputBase-root .MuiOutlinedInput-root {
    border: 1px solid green;
  }

  .MuiTabs-indicator {
    display: none;
  }

  .MuiFormControl-root, .MuiTextField-root  ,.css-tzsjye {
    margin: 0.5rem 0 0 !important;
  }

  .fontFamilyPoppins {
    font-family: "Poppins", sans-serif !important;
  }

  .css-1hiwve1-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,.css-156tkzh-MuiButtonBase-root-MuiPickersDay-root.Mui-selected, .MuiButtonBase-root-MuiPickersDay-root.Mui-selected, .css-17jftv4, .css-19155ru.Mui-selected {
   /*  background-color: #4d22ae !important;
    border-radius: 20%; */
    @apply !bg-gradient-to-r from-OceanBlue to-DeepLilac rounded-[20%]
  }
  .css-i5q14k-MuiDayCalendar-header, .css-1n2mv2k {
    /* background-image: linear-gradient(90deg, #4d22ae, #974ec0) !important; */
    @apply !bg-gradient-to-r from-OceanBlue to-DeepLilac
  }

  .MuiPickersCalendarHeader-root {
    @apply !bg-gradient-to-r from-OceanBlue to-DeepLilac
    !mb-0 !pt-[10px]
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
  }
  .css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected, .MuiButtonBase-root-MuiTab-root.Mui-selected, .css-1qtp7a4.Mui-selected {
    @apply !text-DeepLilac
    /* color: #974ec0 !important; */
  }
  .MuiClockPointer-root,
  .MuiClock-pin,
  .MuiClockPointer-thumb,
  .MuiClockPointer-root {
   /*  background-color: #974ec0 !important; */
    @apply !bg-DeepLilac
  }
  .MuiClockPointer-thumb {
    @apply !border-[16px] !border-solid !border-DeepLilac
    /* border: 16px solid #974ec0 !important; */
  }
  .MuiDayCalendar-monthContainer {
    /* border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; */
  }

.MuiTypography-root-MuiDatePickerToolbar-title {
    /* font-size: 1.5rem !important; */
    @apply !text-2xl
  }

  .MuiAutocomplete-root .MuiOutlinedInput-root {
    /* padding: 0 !important; */
    @apply !p-0
  }

  .css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel, .MuiTypography-root-MuiDayCalendar-weekDayLabel,
  .MuiPickersCalendarHeader-labelContainer,
.MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button,
.MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button, .css-10qkmc3{
   /*  color: #ffffff !important; */
   @apply !text-white
  }
  .css-1hiwve1-MuiButtonBase-root-MuiPickersDay-root,
  .css-156tkzh-MuiButtonBase-root-MuiPickersDay-root,
  .css-156tkzh-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
  .MuiButtonBase-root-MuiPickersDay-root, .css-19155ru,.css-17jftv4 {
    /* line-height: 1.66 !important;
    letter-spacing: 0.03333em !important;
    width: 35px !important;
    height: 30px !important;
    border-radius: 20% !important;
    font-weight: 500 !important;
    background-color: transparent !important; */
    @apply !leading-[1.66] !w-[35px] !h-[30px] !rounded-[20%] !font-medium !bg-transparent !tracking-[0.03333em]
  }

  .css-1e6y48t-MuiButtonBase-root-MuiButton-root, .css-1ujsas3 {
    /* font-size: 0.75rem !important;
    color: #974ec0 !important; */
    @apply !text-xs !text-DeepLilac
  }

  .css-12p2adl-MuiDayCalendar-monthContainer {
/*     background: #f6f7f9 !important;
     @apply !bg-lightGray-0 */
  }

  .MuiDrawer-docked, .MuiDrawer-paper {
    /* border-right: none; */
    @apply border-r-0 sm:min-w-[270px] min-w-[250px]
  }
  /* .css-1vooibu-MuiSvgIcon-root
{
  @apply text-white
} */

  .smallBoldText {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2d2d2d;
  }
  .MuiFormHelperText-root, .css-u36398 {
    font-family: "Poppins", sans-serif !important;
  }

  /* dashboard style*/
  /* .MuiTypography-root {
    font-weight: 500;
    font-size: 15px !important;
  } */

  .css-98a9aw{
    font-weight: 500 !important;
    font-size: 15px !important;
  }

  .css-1r7jdwa, .css-10raonq, .css-194vr1b{
    font-size: 12px !important;
  }

  .css-1wmwtnn, .css-98a9aw, .css-lmbsar, .css-c16olg, .css-1q4npux{
    font-weight: 500 !important;
    font-size: 14px !important;
  }

  .css-6fxtig{
    font-weight: 400 !important;
  }

  /* Table Rows Changes */
  .MuiTableCell-root,
  .MuiTableCell-root,
  .css-1bigob2 {
    color: #8e929a !important;
    font-size: 14px !important;
    text-transform: capitalize;
    line-height: 24px !important;
    font-weight: 600 !important;
    font-family: poppins !important;
  }
  .MuiTypography-root, .css-98a9aw {
    /* color: #423b3b !important; */
    /* font-size: 14px !important;
    font-weight: 500 !important; */
  }
  
  .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase .Mui-checked
    + .MuiSwitch-track,
.MuiSwitch-track .css-1nr2wod .Mui-checked,
.css-1nr2wod.Mui-checked + .MuiSwitch-track,.css-1ju1kxc ,
.css-1yjjitx-MuiSwitch-track {
    background: #ffffff !important;
    box-shadow: inset 4px 0px 10px rgba(0, 0, 0, 0.15),
      inset 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
  }

  /* .css-jsexje-MuiSwitch-thumb {
    background-color: #4d22ae !important;
  } */

  .css-1nr2wod{
    color: none !important;
  }

  /* .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
    color: #4d22ae !important;
  } */
  /* .MuiButtonBase-root-MuiSwitch-switchBase, .css-11hvjc0 ,
  .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase {
    color: #8e929a !important;
  } */

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root, .css-1v4ccyo {
    border-radius: 10px !important;
    border: none !important;
    background-color: #f6f7f9;
    overflow: hidden;
  }
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root, .css-1v4ccyo{
    width: 100% !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
    border-color: transparent !important;
  }
  .MuiInputBase-input-MuiOutlinedInput-input, .css-u36398 {
    font-size: 13px !important;
  }
  .MuiInputBase-root-MuiOutlinedInput-root, .css-1hof3tc {
    padding: 10px 15px !important;
  }

  .css-9l3uo3{
    font-size: 1rem !important;
  }

  .thumbs-wrapper {
    display: none;
  }
  /* .activeBorder2 {
    background: rgba(77, 34, 174, 0.06);
    border: 2px solid #4d22ae;
    border-radius: 20px !important;
  } */
  .activeBorder {
    background: rgba(77, 34, 174, 0.06);
    border: 4px solid #4d22ae;
    border-radius: 20px !important;
  }

  .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused {
    background-color: #0000000a !important;
  }

 .MuiButtonBase-root-MuiMenuItem-root.Mui-selected, .css-mav8s5 {
    background-color: #0000000a !important;
  }

  .gm-style-mtc button {
    font-family: "Poppins", sans-serif;
  }

  button,
  ul li {
    font-family: "Poppins", sans-serif !important;
  }

  /* form datepicker calender style */

  .MuiPickersCalendarHeader-root {
    margin-top: 0 !important;
  }
  .css-1cafy48-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition, .MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition, .css-2ldko6 {
    min-height: auto !important;
  }
  .css-1cafy48-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition>*,
  .MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition
    > *,.css-2ldko6 > * {
    position: relative !important;
  }
  .MuiDialog-paper,
  .MuiPaper-root-MuiDialog-paper, .css-uhb5lp {
    border-radius: 20px !important;
    overflow: hidden !important;
  }

  /* admin table */
  .MuiTableRow-root {
    /* background-color: #F6F7F9; */
    border-radius: 14px;
    height: 57px;
  }
  .MuiTableCell-root {
    padding: 5px 20px !important;
    border: none !important;
    /* display: flex;
    justify-content: center; */
  }
.MuiTypography-root {
    margin-bottom: 0 !important;
  }

  .css-19midj6 {
    padding-bottom: 0 !important;
  }

  .button_labelName__HZshC {
    font-size: 14px !important;
  }
.MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 10px !important;
  }

  .filter-clear-icon {
    font-size: 1.1rem !important;
    transform: translateY(-4px) !important;
  }

.MuiInputBase-input, .css-mnn31t {
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  .statusFont{
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  @media screen and (min-width: 900px) {
    .MuiPaper-root-MuiDrawer-paper {
      min-width: 270px !important;
      padding: 0 !important;
    }
  }

  /* @media screen and (min-width: 1280px) {
    .css-12i7wg6-MuiPaper-root-MuiDrawer-paper{
      min-width: 310px !important;
    }
    } */

  /* body scrollbar */

  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  *::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  *::-webkit-scrollbar-thumb {
    @apply bg-DeepLilac;
    border-radius: 10px;
  }
}

/* loader */

[class*="loader-"] {
  display: inline-block;
  width: 2em;
  height: 2em;
  color: inherit;
  vertical-align: middle;
  pointer-events: none;
  position: fixed !important;
  top: 50%;
  left: 55%;
}

.loader-05 {
  @apply border-t-DeepLilac
  border-[0.2em] border-solid border-transparent
}

.loader-05 {
  /* border: 0.2em solid transparent; */
  /* border-top-color: #974ec0; */
  border-radius: 50%;
  -webkit-animation: 0.7s loader-05 linear infinite;
  animation: 0.7s loader-05 linear infinite;
  position: relative;
}

.loader-05:before {
  @apply  border-[0.2em] border-solid border-DeepLilac
}
.loader-05:before {
  content: "";
  display: block;
  width: inherit;
  height: inherit;
  position: absolute;
  top: -0.2em;
  left: -0.2em;
  /* border: 0.2em solid #974ec0; */
  border-radius: 50%;
  opacity: 0.5;
}

@-webkit-keyframes loader-05 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-05 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* File Upload with drag */
#form-file-upload {
  height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.errordatafeedmodal {
  width: 500;
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  overflow-y: auto;
  max-height: 100%;
}

.MuiBreadcrumbs-separator {
  margin-left: 4px;
  margin-right: 4px;
}

.MuiBreadcrumbs-li {
  cursor: pointer;
}

.MuiButtonBase-root-MuiMenuItem-root:focus,
.MuiButtonBase-root-MuiMenuItem-root:focus,
.MuiButtonBase-root-MuiMenuItem-root:hover,
 .MuiButtonBase-root-MuiMenuItem-root:hover,
 .css-mav8s5:hover, .css-1km1ehz:hover, .css-1km1ehz:hover .css-mav8s5:focus, .css-1km1ehz:focus, .css-1km1ehz:focus{
  text-decoration: none;
  background-color: #0000000a !important;
}

#nprogress {
  pointer-events: none;
}
#nprogress .bar {
  left: 0;
  top: 0;
  height: 3px;
  width: 100%;
  z-index: 2000;
  position: fixed;
  background-color: #4d22ae;
}

.MuiList-root-MuiMenu-list{
  max-height: 40vh !important;
}

.css-2s90m6-MuiAvatar-root,.MuiAvatar-root .MuiAvatar-circular .MuiAvatar-colorDefault, .css-154ogbs{
  width:20px !important;
  height:20px !important;
  background-color: #ffffff !important;
}
.lgtRQG, .jXOzAn
{
  display: none !important;
}
.MuiAutocomplete-option {
  @apply !border-[1px] !border-solid !border-DeepLilac !text-sm !min-h-[35px] !p-[10px] !rounded-borderRadius10 !my-[5px] !mx-[10px] !bg-white capitalize !font-fontPoppins;
}

.css-17jftv4:not(.Mui-selected){
  background-color: #ffffff !important;
  background-image: none !important;
}

/* Remove Arrow from input type number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.MuiPaper-root-MuiMenu-paper-MuiPopover-paper{
  max-height: 250px !important;
}

.MuiPaper-root-MuiDialog-paper, .css-1qmc5dd{
  border-radius: 20px !important;
}
.css-1je35ee-MuiTypography-root {
  color: #423b3b !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select,.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,.css-195crav-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input,.MuiInputBase-input-MuiOutlinedInput-input, .MuiInputBase-root-MuiOutlinedInput-root, .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select, .css-1h9r77c, .css-1uvydh2, .css-1x5jdmq, .css-qiwgdb, .location-search-input {
  align-items: center!important;
  display: flex!important;
  font-family: Poppins!important;
  font-size: 13px!important;
  height: 47px!important;
  padding: 0 15px!important;
}
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root,.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select,.MuiAutocomplete-hasClearIcon.MuiAutocomplete-root .MuiOutlinedInput-root, .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input, .css-qiwgdb {
  background-color: #f6f7f9!important;
  border-radius: 10px!important;
}
.css-10ghrmp-MuiPaper-root-MuiAppBar-root{
  @apply !bg-DeepLilac
}